<template>
  <div class="driver-management" ref="driverManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddDriver"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="importExcel"
        v-if="$store.state.menu.nowMenuList.indexOf('导入') >= 0"
        >导入</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="84px"
      >
        <el-form-item label="姓名：">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="所属企业：">
          <companySelect
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="驾驶证类型：">
          <el-select clearable v-model.trim="form.drivingType">
            <el-option
              v-for="item in drivingTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select clearable v-model.trim="form.status">
            <el-option
              v-for="item in statusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>

      <el-table-column prop="name" label="姓名" width="120"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        :show-overflow-tooltip="true"
        width="170"
      ></el-table-column>
      <el-table-column prop="drivingType" label="驾驶证类型">
        <template slot-scope="scope">{{
          drivingTypeObj[Number(scope.row.drivingType)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="workNum"
        label="工号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号码"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="credentialsEndDate"
        label="从业资格证到期日期"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">{{
          statusObj[Number(scope.row.status)]
        }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateDriver(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="
              $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
              scope.row.status === '0'
            "
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === '0' ? 'danger' : 'primary'"
            @click="onDisableUser(scope.row)"
            v-if="
              scope.row.status === '0'
                ? $store.state.menu.nowMenuList.indexOf('禁用') >= 0
                : $store.state.menu.nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === "0" ? "禁用" : "启用" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="70%"
    >
      <div>
        <div class="body">
          <p class="form-title">驾驶员基本信息</p>
          <el-form
            :model="addForm"
            :rules="rules"
            :inline="true"
            ref="addForm"
            label-width="110px"
            label-position="left"
            class="demo-form-inline dialog-form dialog-form-inline"
          >
            <el-form-item label="驾驶员姓名：" prop="name">
              <el-input
                v-model.trim="addForm.name"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>

            <el-form-item label="所属企业：" prop="companyId">
              <companySelect
                ref="companySelect"
                :clearable="true"
                :companyTree="companyList"
                :valueName="valueNameAdd"
                :value="valueAdd"
                @getValue="getAddGroupId"
              ></companySelect>
            </el-form-item>

            <el-form-item label="工号：" prop="workNum">
              <el-input
                v-model.trim="addForm.workNum"
                placeholder="请输入工号"
              ></el-input>
            </el-form-item>

            <el-form-item label="性别：" prop="sex">
              <el-select clearable v-model.trim="addForm.sex">
                <el-option
                  v-for="item in sexList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="身份证号码：" prop="idCard">
              <el-input
                v-model.trim="addForm.idCard"
                :disabled="isIDCardEdit"
                placeholder="请输入身份证号码"
              ></el-input>
            </el-form-item>

            <el-form-item label="联系电话：" prop="phone">
              <el-input
                v-model.trim="addForm.phone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>

            <el-form-item label="星级：" prop="starLevel">
              <el-select clearable v-model.trim="addForm.starLevel">
                <el-option
                  v-for="item in starLevelList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <p class="from-title form-title3">驾驶员照片</p>
          <div class="form-area photo-area">
            <el-upload
              :action="uploadUrl()"
              :headers="getHeader()"
              accept="image/png, image/gif, image/jpg, image/jpeg"
              list-type="picture-card"
              :limit="3"
              :file-list="addForm.photos"
              :before-remove="handleBeforeRemove"
              :on-success="handleAvatarSuccess"
              :class="{ disUoloadSty: isPhotoExceed }"
              ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>

          <p class="form-title form-title3">
            <span>从业资格证信息</span>
          </p>
          <el-form
            :model="addForm2"
            :rules="rules"
            :inline="true"
            ref="addForm2"
            label-width="110px"
            label-position="left"
            class="demo-form-inline dialog-form dialog-form-inline"
          >
            <el-form-item label="资格证号：" prop="credentialsNo">
              <el-input
                v-model.trim="addForm2.credentialsNo"
                placeholder="请输入从业资格证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="到期时间：" prop="credentialsEndDate">
              <el-date-picker
                v-model="addForm2.credentialsEndDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-form>

          <p class="form-title form-title3">
            <span>驾驶证信息</span>
          </p>
          <el-form
            :model="addForm3"
            :rules="rules"
            :inline="true"
            ref="addForm3"
            label-width="110px"
            label-position="left"
            class="demo-form-inline dialog-form dialog-form-inline"
          >
            <el-form-item label="驾驶证号码：" prop="drivingLicense">
              <el-input
                v-model.trim="addForm3.drivingLicense"
                :disabled="true"
                placeholder="请输入驾驶证号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="到期时间：" prop="drivingEndDate">
              <el-date-picker
                v-model="addForm3.drivingEndDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="驾驶证类型：" prop="drivingType">
              <el-select clearable v-model.trim="addForm3.drivingType">
                <el-option
                  v-for="item in drivingTypeList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>

    <!-- 导入 -->
    <el-dialog title="导入" :visible.sync="downVisible" width="30%">
      <uploadFile
        @uploadSuccess="uploadSuccess"
        url="base/driver/importDriver"
        class="import"
        :type="1"
      ></uploadFile>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDriverList,
  insertDriver,
  updateDriver,
  getBusinessCompanyTree,
  getDriverDetail,
  updateDriverStatus,
  queryDictsByCodes,
  queryUserGroupTypeList
} from '@/api/lib/api.js'
import {
  checkName,
  checkJobNumber,
  checkIdentificationNumber,
  checkPhone,
  checkQualificationCertificateNumber,
  checkDriverLicenseNumber,
  getCurent,
  formatDate
} from '@/common/utils/index.js'
import config from '@/api/static/config'
import { formatDict } from '@/common/utils/index'
import uploadFile from '@/components/uploadFile/uploadFile.vue'
import companySelect from '@/components/treeSelect/companySelect.vue'

import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'driverManagement',
  components: {
    uploadFile,
    companySelect
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      form: {
        name: null,
        companyId: null,
        drivingType: null,
        status: null,
        currentPage: 1,
        pageSize: 10
      },
      addForm: {
        name: '',
        companyId: '',
        workNum: '',
        sex: '',
        idCard: '',
        status: '0',
        phone: '',
        starLevel: '',
        photos: []
      },
      addForm2: {
        credentialsNo: '',
        credentialsEndDate: ''
      },
      addForm3: {
        drivingType: '',
        drivingLicense: '',
        drivingEndDate: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入驾驶员姓名', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属企业', trigger: 'blur' }
        ],
        workNum: [
          { required: true, message: '请输入工号', trigger: 'blur' },
          { validator: checkJobNumber, trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        idCard: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { validator: checkIdentificationNumber, trigger: 'blur' }
        ],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        starLevel: [{ required: true, message: '请选择星级', trigger: 'blur' }],
        credentialsNo: [
          { required: true, message: '请输入从业资格证号', trigger: 'blur' },
          { validator: checkQualificationCertificateNumber, trigger: 'blur' }
        ],
        credentialsEndDate: [
          { required: true, message: '请选择到期时间', trigger: 'change' }
        ],
        drivingEndDate: [
          { required: true, message: '请选择到期时间', trigger: 'change' }
        ],
        drivingLicense: [
          { required: true, message: '请输入驾驶证号', trigger: 'blur' },
          { validator: checkDriverLicenseNumber, trigger: 'blur' }
        ],
        drivingType: [
          { required: true, message: '请选择驾驶证类型', trigger: 'change' }
        ]
      },
      total: 0,
      dialogVisible: false,
      title: '',
      companyList: [],
      sexList: [],
      statusList: [],
      starLevelList: [],
      drivingTypeList: [],
      drivingTypeObj: {},
      statusObj: {},
      terminalData: [
        {
          deviceNo: '',
          deviceType: '',
          SIMNo: ''
        }
      ],
      isPhotoExceed: false,
      isIDCardEdit: false,
      editLoading: false,
      exportForm: {},
      downLoadStatus: false,
      downVisible: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.driverManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'base',
        userId: sessionStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `驾驶员信息-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/driver/driverExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    onSearch () {
      this.form.currentPage = 1
      this.getTableData()
    },
    // 获取表格数据
    getTableData () {
      getDriverList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
        this.exportForm = { ...this.form }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getTableData()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.onSearch()
    },

    // 点击表格查看按钮
    onUpdateDriver (row) {
      this.title = '修改驾驶员信息'
      getDriverDetail({ id: row.id }).then((res) => {
        if (res.code === 1000) {
          this.$nextTick(() => {
            this.valueAdd = res.data.companyId
            this.valueNameAdd = row.companyName
          })
          this.addForm.photos = []
          if (res.data.driverPhoto) {
            res.data.driverPhoto.forEach((item, index) => {
              this.addForm.photos.push({
                name: 'pic-' + index,
                url: item
              })
            })
          }

          this.addForm = {
            id: row.id,
            name: res.data.name,
            companyId: res.data.companyId,
            workNum: res.data.workNum,
            sex: res.data.sex + '',
            idCard: res.data.idCard,
            status: res.data.status + '',
            phone: res.data.phone,
            starLevel: res.data.starLevel + '',
            photos: this.addForm.photos
          }
          this.addForm2 = {
            credentialsNo: res.data.credentialsNo,
            credentialsEndDate: res.data.credentialsEndDate
          }
          this.addForm3 = {
            drivingType: res.data.drivingType,
            drivingLicense: res.data.drivingLicense,
            drivingEndDate: res.data.drivingEndDate
          }
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isIDCardEdit = true
      this.dialogVisible = true
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.$refs.companySelect.clearHandle()
      this.dialogVisible = false
      this.$refs.upload.clearFiles()
      this.isPhotoExceed = false
      this.$refs.addForm.resetFields()
      this.$refs.addForm2.resetFields()
      this.$refs.addForm3.resetFields()
      this.addForm.driverPhoto = []
      this.addForm.photos = []
      this.valueAdd = null
      this.valueNameAdd = ''
    },
    // 新增驾驶员
    onAddDriver () {
      this.title = '新增驾驶员'
      this.isIDCardEdit = false
      this.dialogVisible = true
    },
    //  禁用|启用 驾驶员
    onDisableUser (row) {
      if (row.status === '0') {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [
            h('span', null, '确定禁用驾驶员 '),
            h('span', { style: 'color: #059370' }, row.name),
            h('span', null, ' ？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            updateDriverStatus({ id: row.id, status: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('禁用成功')
                row.status = '1'
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消禁用'
            })
          })
      } else {
        updateDriverStatus({ id: row.id, status: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.status = '0'
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 新增/修改驾驶员
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$refs.addForm2.validate((valid) => {
            if (valid) {
              this.$refs.addForm3.validate((valid) => {
                if (valid) {
                  this.editLoading = true
                  this.addForm.driverPhoto = []
                  this.addForm.photos.forEach((item) => {
                    if (item.name) {
                      this.addForm.driverPhoto.push(item.url)
                    } else {
                      this.addForm.driverPhoto.push(item)
                    }
                  })
                  const data = {
                    ...this.addForm,
                    ...this.addForm2,
                    ...this.addForm3
                  }
                  if (this.title.indexOf('新增') !== -1) {
                    delete data.id
                    insertDriver(data).then((res) => {
                      if (res.code === 1000) {
                        this.$message.success('新增成功')
                        this.onDialogClose()
                        this.onSearch()
                      } else {
                        this.$message.error(res.msg)
                      }
                      this.editLoading = false
                    })
                  } else {
                    updateDriver(data).then((res) => {
                      if (res.code === 1000) {
                        this.$message.success('修改成功')
                        this.onDialogClose()
                        this.getTableData()
                      } else {
                        this.$message.error(res.msg)
                      }
                      this.editLoading = false
                    })
                  }
                }
              })
            }
          })
        }
      })
    },
    // 限制只能输入数字
    onOnlyNumber (row, str) {
      const reg = /^[1-9]\d*$/
      if (!new RegExp(reg).test(row[str])) {
        row[str] = ''
      }
    },
    // 添加设备
    onAddTerminal () {
      this.terminalData.push({
        deviceNo: '',
        deviceType: '',
        SIMNo: ''
      })
    },
    // 删除设备
    onDeleteTerminal (i) {
      this.terminalData.splice(i, 1)
    },
    // 移除照片确认
    handleBeforeRemove (file, fileList) {
      this.addForm.photos.forEach((item, index) => {
        if (file.response) {
          if (item === file.response.data) {
            this.addForm.photos.splice(index, 1)
          }
        } else {
          if (item.url === file.url) {
            this.addForm.photos.splice(index, 1)
          }
        }
      })
    },
    // 成功添加照片
    handleAvatarSuccess (res, file) {
      if (res.code === 1000) {
        this.addForm.photos.push(res.data)
      }
    },
    // 上传接口
    uploadUrl () {
      return config.apiBaseUrl + 'base/driver/uploadDriverPicture'
    },
    // 上传请求头
    getHeader () {
      return {
        Authorization: sessionStorage.getItem('token'),
        token: sessionStorage.getItem('token')
      }
    },
    getGroupId (v) {
      this.form.companyId = v
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    //导入
    importExcel () {
      this.downVisible = true
    },
    //导入成功
    uploadSuccess () {
      this.downVisible = false
      this.onSearch()
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'ZT,JSZLX,XB,XJ' }).then((res) => {
        if (res.code === 1000) {
          this.statusList = res.data.ZT
          this.drivingTypeList = res.data.JSZLX
          this.sexList = res.data.XB
          this.starLevelList = res.data.XJ
          this.statusObj = formatDict(this.statusList)
          this.drivingTypeObj = formatDict(this.drivingTypeList)
        }
      })
    }
  },
  created () {
    this.getCompanyList()
    this.getDicts()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  watch: {
    'addForm.photos' (val) {
      if (val.length > 2) {
        this.isPhotoExceed = true
      } else {
        this.isPhotoExceed = false
      }
    },
    'addForm.idCard' (val) {
      this.addForm3.drivingLicense = val
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.driver-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .form-title3 {
      margin: 10px 0 10px;
    }
    .photo-area {
      padding-bottom: 16px;
    }
  }
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-resourse {
  .driver-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
